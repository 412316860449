import React from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import overlayPreviewData from '../../../../data/previews/overlay.yml';
import pageHeroData from '../../../../data/pages/components.yml';
import styles from './styles/overlay.module.scss';

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const DataTable = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.DataTable;
    return <Component {...props} />;
  },
});

const Overlay = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Overlay;
    return <Component {...props} />;
  },
});

const Subhead = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Subhead;
    return <Component {...props} />;
  },
});

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const IconSlowBackward = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconSlowBackward;
    return <Component {...props} />;
  },
});

const IconPlay = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconPlay;
    return <Component {...props} />;
  },
});

const IconRewind = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconRewind;
    return <Component {...props} />;
  },
});

const IconFastForward = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconFastForward;
    return <Component {...props} />;
  },
});

const IconSlowForward = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconSlowForward;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOverlayOpen: false,
      currentCase: 'primary',
    };
  }

  handleCaseChange(value) {
    this.setState({
      currentCase: value,
    });
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Overlay"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Dialogs"
          tierThree="Overlay"
        />
        <V5Notice
          componentName="Overlay"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/overlays-dialogs--docs#overlay"
        />

        <PageNavigation
          links={[
            'Cases',
            'Behavior',
            'Mobile',
            'Usage',
            'Microcopy',
            'Platform',
          ]}
        />

        <Section title="Cases">
          <Paragraph>
            It’s all a matter of how the overlay workflow relates to the main
            interface.
          </Paragraph>

          <ComponentPreview
            name="Overlay"
            layout="row"
            previewData={overlayPreviewData.cases}
            onChange={this.handleCaseChange.bind(this)}>
            <Button
              buttonType="primary"
              onClick={() => this.setState({ isOverlayOpen: true })}
              ignoreThisComponent>
              Trigger Overlay
            </Button>
            <Overlay
              isOpen={this.state.isOverlayOpen}
              onClose={() => this.setState({ isOverlayOpen: false })}>
              {this.state.currentCase === 'primary' ? (
                <div className={styles.primaryOverlayWrapper}>
                  <div className={styles.primaryOverlayVideo}>
                    <img
                      src={require('../../../../imgs/previews/preview-overlay-video.jpg')}
                    />
                    <div className={styles.primaryOverlayVideoControls}>
                      <IconSlowBackward />
                      <IconRewind />
                      <IconPlay />
                      <IconFastForward />
                      <IconSlowForward />
                    </div>
                  </div>
                  <div className={styles.primaryOverlayDetails}>
                    <Subhead>Defensive Rebound Percentage</Subhead>
                    <Text
                      level="micro"
                      color="subtle"
                      className="uni-margin--one--bottom">
                      3m 52s 29 Clips from 1 Video
                    </Text>
                    <Button size="small" isBlock buttonType="secondary">
                      Send to Highlights
                    </Button>
                    <ul className={styles.primaryOverlayList}>
                      <li>Def Rebound at 0:56</li>
                      <li>Def Rebound at 3:04</li>
                      <li>Def Rebound at 6:49</li>
                      <li>Def Rebound at 7:05</li>
                      <li>Def Rebound at 9:17</li>
                      <li>Def Rebound at 9:44</li>
                      <li>Def Rebound at 12:08</li>
                      <li>Def Rebound at 15:56</li>
                    </ul>
                  </div>
                </div>
              ) : (
                <DataTable
                  columnContentTypes={[
                    'text',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                  ]}
                  columnShowSortArrows={[false, false, false, false, false]}
                  columnHeaders={['', 'G', 'MP', 'FG', 'FGA', 'FG%']}
                  rows={[
                    {
                      id: '1',
                      data: ['Team', '75', '17985', '2944', '6480', '.454'],
                    },
                    {
                      id: '2',
                      data: ['Team/G', '', '243.0', '39.8', '87.6', '.454'],
                    },
                    { id: '3', data: ['Lg Rank', '', '4', '20', '20', '18'] },
                    {
                      id: '4',
                      data: ['Year/Year', '', '0.4%', '2.9%', '-1.4%', '+.019'],
                    },
                    {
                      id: '5',
                      data: ['Opponent', '77', '18705', '3247', '6871', '.473'],
                    },
                    {
                      id: '6',
                      data: ['Opponent/G', '', '242.9', '42.2', '89.2', '.473'],
                    },
                    { id: '7', data: ['Lg Rank', '', '5', '25', '18', '27'] },
                    {
                      id: '8',
                      data: ['Year/Year', '', '0.3%', '2.9%', '2.8%', '+.000'],
                    },
                    {
                      id: '9',
                      data: ['Team', '75', '17985', '2944', '6480', '.454'],
                    },
                    {
                      id: '10',
                      data: ['Team/G', '', '243.0', '39.8', '87.6', '.454'],
                    },
                    { id: '11', data: ['Lg Rank', '', '4', '20', '20', '18'] },
                    {
                      id: '12',
                      data: ['Year/Year', '', '0.4%', '2.9%', '-1.4%', '+.019'],
                    },
                    {
                      id: '13',
                      data: ['Opponent', '77', '18705', '3247', '6871', '.473'],
                    },
                    {
                      id: '14',
                      data: ['Opponent/G', '', '242.9', '42.2', '89.2', '.473'],
                    },
                    { id: '15', data: ['Lg Rank', '', '5', '25', '18', '27'] },
                    {
                      id: '16',
                      data: ['Year/Year', '', '0.3%', '2.9%', '2.8%', '+.000'],
                    },
                  ]}
                />
              )}
            </Overlay>
          </ComponentPreview>
        </Section>

        <Section title="Behavior">
          <Paragraph>
            The overlay has a couple of fun quirks. One is automatic, the other
            is up to you.
          </Paragraph>
          <SectionSubhead>Sizing</SectionSubhead>
          <Paragraph>
            The overlay is designed to fill 80% of the viewport, in both height
            and width. <em>However</em>, at 1024px it responds to fill the
            entire view. (That’s the same point at which our navbar changes, so
            it shouldn’t seem too weird.)
          </Paragraph>
          <SectionSubhead>Dismiss</SectionSubhead>
          <Paragraph>
            A dismissal method isn’t required, but you have options as to what
            would do the trick.
          </Paragraph>
          <List type="unordered">
            <li>Hitting the ESC key</li>
            <li>Clicking the scrim</li>
            <li>
              Clicking the dismiss—but you can’t have this without the rest of
              the header.
            </li>
          </List>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Like the modal, design separate interfaces using the{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/app-architecture/modality/">
              modal view
            </Link>
            . Reserve any interjection on mobile to the{' '}
            <Link href="/components/dialogs/alert/design/">alert</Link>.
          </Paragraph>
        </Section>
        <Section title="Usage">
          <SectionSubhead>Header</SectionSubhead>
          <Paragraph>
            <strong>The overlay’s header is optional.</strong> If you choose to
            include the header, it comes with a title and dismiss. You can’t
            have one without the others!
          </Paragraph>
          <DontDo
            dontText="avoid a header by incorporating a title into the content."
            doText="include a header if the title and/or dismiss would benefit the workflow."
            imgFilename="overlay-header"
          />

          <SectionSubhead>Imported Content</SectionSubhead>
          <Paragraph>
            All imported content should come with its natural{' '}
            <Link href="/components/themes/environment/design">
              environment
            </Link>
            , regardless of the main interface environment. If there is no
            “natural” environment, mirror that of the main interface.
          </Paragraph>
          <DontDo
            dontText="rely on the environment of the main interface for all imported content."
            doText="inherit the environment relative to the overlay’s content."
            imgFilename="overlay-imported-content"
          />
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Titles</SectionSubhead>
          <Paragraph>
            If you include a title, make sure it explains what they’re seeing or
            doing in the overlay. Keep it brief with keywords at the front,
            title case. Our{' '}
            <Link href="http://hudl.com/design/writing/writing-for/teams/product#UI%20Labels">
              label guidelines
            </Link>{' '}
            can help.
          </Paragraph>
          <DontDo
            dontText="use the title as a means of onboarding the user to that workflow."
            doText="clarify what the triggered overlay allows the user to do."
            imgFilename="overlay-titles"
          />
          <SectionSubhead>Buttons</SectionSubhead>
          <Paragraph>
            If your overlay features any buttons, each should very clearly state
            its action. Avoid complete sentences, opt for a strong verb phrase
            instead. Title case always.
          </Paragraph>
          <Paragraph>
            You can read more about button microcopy in our{' '}
            <Link href="/components/buttons/button/design">
              button guidelines
            </Link>
            .
          </Paragraph>
        </Section>
        <Section title="Platform">
          <PlatformTable />
        </Section>
      </PageWithSubNav>
    );
  }
}
